import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

const SHRINK_HEADER_ON = 50;
window.addEventListener('scroll', function(){
  if (document.body.scrollTop > SHRINK_HEADER_ON || document.documentElement.scrollTop > SHRINK_HEADER_ON) {
    document.querySelector('nav').classList.add('smaller');
  } else {
    document.querySelector('nav').classList.remove('smaller');
  }
});

serviceWorker.unregister();
