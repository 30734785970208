import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Navbar from './components/Navbar';
import Main from './components/Main';
import Portfolio from './components/Portfolio';
import Contacts from './components/Contacts';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Navbar/>
        <Switch>
          <Route exact path='/' component={Main}/>
          <Route path='/portfolio/:workId' component={Portfolio}/>
          <Route path='/portfolio' component={Portfolio}/>
          <Route path='/contacts' component={Contacts}/>
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
