import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import logo from '../img/logo-mr.svg';

const Navbar = () => {
  return (
    <nav className="nav-wrapper">
      <div className="logo">
        <Link to='/'><img src={logo} alt="MR"/></Link>
      </div>
      <div className="menu">
        <NavLink to='/portfolio'>Portfolio</NavLink>
        <NavLink to='/contacts'>Contacts</NavLink>
      </div>
    </nav>
  );
};

export default Navbar;