import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import firebase from '../config/fbConfig';
import Preloader from './Preloader';

class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {imageClassName: null, photoUrl: '', overlayedContent: null};
  }

  componentDidMount() {
    this.setState({imageClassName: 'loading'});
    firebase.storage()
      .ref('marina-rakhimova.png')
      .getDownloadURL()
      .then(url => this.setState({photoUrl: url}));
  }

  handleImageLoad = () => this.setState({imageClassName: null});

  handleOverlayImageLoad = event => event.target.classList.remove('loading');

  openOverlayedContent = path => () => {
    this.setState({overlayedContent: {loading: true}});
    firebase.storage()
      .ref(path)
      .getDownloadURL()
      .then(url => this.setState({overlayedContent: {loading: false, url}}));
  };

  closeOverlayedContent = () => this.setState({overlayedContent: null});

  render() {
    const {photoUrl, imageClassName, overlayedContent} = this.state;
    const overlayedContentBlock = overlayedContent
      ? <div className="scrollable-overlay">
        <span className="close-btn" onClick={this.closeOverlayedContent}>Close</span>
        <div className="work-page">
          <div className="case-content">
            {overlayedContent.loading && <Preloader/>}
            <img onLoad={this.handleOverlayImageLoad} className="loading" src={overlayedContent.url} alt=""/>
          </div>
        </div>
      </div>
      : null;
    return (
      <div className="content contacts">
        <h1 className="page-heading"><span className="transparent">Contact</span> me</h1>
        <ul className="links">
          <li><a target="_blank" rel="noopener noreferrer" className="underline-hover"
                 href="tel:+491633699560">+49&nbsp;1&nbsp;633&ndash;699&ndash;560</a>
          </li>
          <li><a target="_blank" rel="noopener noreferrer" className="underline-hover"
                 href="mailto:mpaskina@gmail.com">mpaskina@gmail.com</a></li>
          <li><a target="_blank" rel="noopener noreferrer" className="underline-hover"
                 href="https://www.facebook.com/mpaskina">facebook</a></li>
          <li><a target="_blank" rel="noopener noreferrer" className="underline-hover"
                 href="https://www.behance.net/rakhimova">behance</a></li>
        </ul>
        <div className="about">
          <a target="_blank" className="photo" rel="noopener noreferrer" href="https://www.facebook.com/mpaskina">
            {(!photoUrl || imageClassName === 'loading') && <Preloader/>}
            <img onLoad={this.handleImageLoad} className={imageClassName}
                 src={photoUrl} alt="Marina Rakhimova"/>
          </a>
          <div className="description">
            <div className="category-name">About me</div>
            <div className="block-heading">Hi! Most of the time, I&nbsp;design web interfaces and
              I'm&nbsp;not afraid to&nbsp;experiment
            </div>
            <div>I always try to look into the future while analyzing user experience.<br/>I live in Berlin, Germany.
              I’m available for freelance work. Feel free to contact me.
            </div>
          </div>
        </div>
        {overlayedContentBlock}
        <div className="cv">
          <div className="category-name">CV</div>
          <h2 className="work-title cv-title">Digital Experience Designer</h2>
          <table className="experience">
            <tbody>
            <tr className="experience-item">
              <td className="years">2018 &ndash; present</td>
              <td>Freelance Digital Designer</td>
              <td className="type">Work Experience</td>
            </tr>
            <tr className="experience-item">
              <td className="years">2019</td>
              <td>Web Design Pro course by Skillbox, see the <span
                onClick={this.openOverlayedContent('certificates/rakhimova-skillbox-web-design-pro.png')}
                className="underline-hover">certificate</span>
              </td>
              <td className="type">Education</td>
            </tr>
            <tr className="experience-item">
              <td className="years">2014 &ndash; 2019</td>
              <td>
                Bachelor's degree in Digital design, Moscow Economic Institute, <a target="_blank"
                                                                                   rel="noopener noreferrer"
                                                                                   className="underline-hover"
                                                                                   href="https://design.sredaobuchenia.ru/marina_rahimova">
                read about my graduation project
              </a>
              </td>
              <td className="type">Education</td>
            </tr>
            <tr className="experience-item">
              <td className="years">2018</td>
              <td>UI / UX Designer, <a target="_blank" rel="noopener noreferrer" className="underline-hover"
                                       href="http://videinfra.com">videinfra.com</a></td>
              <td className="type">Work Experience</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="see-my-works">
          <Link to="/portfolio" className="action-link">See my works</Link>
        </div>
      </div>
    );
  }
}

export default Contacts;