import React, {Component} from 'react';
import firebase from '../config/fbConfig';
import Preloader from "./Preloader";

class Work extends Component {
  constructor(props) {
    super(props);
    this.state = {content: []};
  }

  componentDidMount() {
    const firestore = firebase.firestore();
    const {work} = this.props;
    const content = [];
    firestore.collection(`works/${work.id}/content`)
      .orderBy('order')
      .get()
      .then(docs => {
        const storage = firebase.storage();
        if (docs.size) {
          /*
            - getting content from the "content" sub-collection of the work
            - content sub-collection is used, when there is complex content is required
              like links, embedded video, html
          */
          docs.forEach(doc => {
            const item = {...doc.data()};
            content.push(item);
            if (item.type === 'image') {
              storage.ref(`works/${work.id}/${item.path}`)
                .getDownloadURL()
                .then(url => {
                  item.url = url;
                  this.setState({content});
                });
            } else {
              this.setState({content});
            }
          });
        } else {
          /*
            - "content" sub-collection is empty
            - check storage for the folder with images
            - case content will load images from that folder
           */
          storage.ref(`works/${this.props.work.id}`)
            .listAll()
            .then(result =>
              result.items.forEach(imageRef => {
                const item = {type: 'image'};
                content.push(item);
                imageRef.getDownloadURL().then(url => {
                  item.url = url;
                  this.setState({content});
                });
              }),
            );
        }
      });
  }

  handleImageLoad = (e) => {
    // "loading" class should be removed only after the image is fully rendered
    // After the image has loaded, it is rendered during the next animation frame,
    // so we need to wait two animation frames and remove the "loading" class
    // This is required to make image appear smoothing using ease-in-out transition
    const image = e.target;
    requestAnimationFrame(
      () => requestAnimationFrame(() => image.classList.remove('loading'))
    );
  };

  render() {
    const {work, categoryTitle, className} = this.props;
    const caseContent = this.state.content.map((item, index) => {
      switch (item.type) {
        case 'image':
          if (item.url) {
            if (work.spaceBetween) {
              item.style = {paddingBottom: work.spaceBetween};
            }
            const image = <img className="loading" onLoad={this.handleImageLoad} style={item.style}
                               src={item.url} alt={`${work.title}: ${index + 1}`} key={item.url}/>;
            return item.link
              ? <a href={item.link} rel="noopener noreferrer" target="_blank" key={item.url}>{image}</a>
              : image;
          }
          return index ? null : <Preloader key={index}/>; // return preloader only for the first image
        case 'embed':
          // Strip away all script tags
          const SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
          while (SCRIPT_REGEX.test(item.html)) {
            item.html = item.html.replace(SCRIPT_REGEX, '');
          }
          if (item.width && item.height) {
            item.style = {paddingBottom: item.height / item.width * 100 + '%'}
          }
          return <div className="embed-container" style={item.style} key={item.order} dangerouslySetInnerHTML={{__html: item.html}} />;
        default:
          return null;
      }
    });
    return (
      <div className={(className ? className : '') + ' work-page'}>
        <div className="category-name">
          {categoryTitle}
        </div>
        <h2 className="work-title">{work.title}</h2>
        <div className="case-content">
          {caseContent.length ? caseContent : <Preloader/> }
        </div>
      </div>
    );
  }
}

export default Work;