import React from 'react';
import {Link} from 'react-router-dom';

const Main = () => {
  return (
    <main>
      <h1 className="page-heading">
        <span className="transparent">UI/UX<br/>Designer</span><br/>
        Marina<br/>Rakhimova
      </h1>
      <div className="main-action">
        <Link to='/portfolio' className="action-link">View works</Link>
      </div>
    </main>
  );
};

export default Main;