import {Component} from 'react';
import {withRouter} from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
// kick off the polyfill!
smoothscroll.polyfill();

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    const prevPage = prevProps.location.pathname.split('/');
    const newPage = this.props.location.pathname.split('/');
    if (prevPage[1] !== newPage[1] || prevPage[1] !== 'portfolio') {
      // Scroll to top on route change, except portfolio page, where a work opens in a modal overlay, no scroll required
      window.scrollTo({top: 0, left: 0});
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)