import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

const config = {
  apiKey: "AIzaSyCUx9Wp0CC_DX7D5D_q-8Wg9485AjtzyaM",
  authDomain: "rakhimova-pro.firebaseapp.com",
  databaseURL: "https://rakhimova-pro.firebaseio.com",
  projectId: "rakhimova-pro",
  storageBucket: "rakhimova-pro.appspot.com",
  messagingSenderId: "466583736308",
  appId: "1:466583736308:web:a05243601c322e34"
};
firebase.initializeApp(config);

export default firebase;