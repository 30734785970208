import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import WorkSummary from './WorkSummary';
import Work from './Work';
import firebase from '../config/fbConfig';
import Preloader from './Preloader';

class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      works: [],
      leftMenuFixed: false,
      noScroll: false
    };
    this.initialFiltersOffset = 0;
  }

  setFiltersPosition = () => {
    const filters = document.getElementById('js-category-menu');
    if (!this.initialFiltersOffset) { // remember initial position of filters
      this.initialFiltersOffset = filters.offsetTop;
    }
    /*
      If filters position is upper than the middle of the screen,
      set their position 'fixed', putting them in the middle of the screen
    */
    filters.classList.toggle(
      'fixed',
      this.initialFiltersOffset - window.scrollY <= (document.documentElement.clientHeight - filters.offsetHeight) / 2
    );
  };

  componentDidMount() {
    const firestore = firebase.firestore();
    const storage = firebase.storage();
    const categories = [], works = [];
    Promise.all([
      firestore.collection('categories').orderBy('order').get(),
      firestore.collection('works').orderBy('order').get(),
    ]).then(([categoryDocs, workDocs]) => {
      categoryDocs.forEach(doc => categories.push({id: doc.id, ...doc.data()}));
      workDocs.forEach(
        doc => works.push({
          id: doc.id,
          ...doc.data(),
          previewPromise: storage.ref(`works/${doc.id}.png`).getDownloadURL()
        })
      );
      this.setState({categories, works}, this.setFiltersPosition);
    });
    window.addEventListener('scroll', this.setFiltersPosition);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {works} = this.state;
    if (this.props.location.search !== prevProps.location.search) {
      // When a category changes, remove and add again filtered works, so they will re-render
      // This is required for fading in animation
      this.setState({works: []}, () => {
        this.setState({works: works});
      });
      window.scrollTo({top: this.initialFiltersOffset - 64, behavior: 'smooth'});
    }
  }

  filterWorks = works => {
    const {search} = this.props.location;
    const categoryMatch = search.match(/\?category=([\w\d-]+)/);
    let categoryId = null, worksFiltered = works;
    if (categoryMatch) { // category id is chosen => filter the works
      categoryId = categoryMatch[1];
      worksFiltered = worksFiltered.filter(work => work.categoryId === categoryId);
      if (worksFiltered.length === 0) { // category not found
        worksFiltered = works;
        categoryId = null;
      }
    }
    if (search.indexOf('showHidden=1') === -1) {
      // filter out hidden works
      worksFiltered = worksFiltered.filter(work => work.hidden !== true);
    }
    return {categoryId, worksFiltered};
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.setFiltersPosition);
  }

  render() {
    const {works, categories, leftMenuFixed} = this.state;
    const {categoryId, worksFiltered} = this.filterWorks(works);
    const filters = categories.length ? [{id: null, title: 'All works'}, ...categories] : [];

    let workRender = null;
    const workToOpen = works.find(work => work.id === this.props.match.params.workId);
    if (workToOpen) {
      const closeLink = `/portfolio${categoryId ? '?category=' + categoryId : ''}`;
      workRender = (
        <div className="scrollable-overlay">
          <Link to={closeLink} className="close-btn">Close</Link>
          <Work work={workToOpen}
                categoryTitle={categories.find(category => category.id === workToOpen.categoryId).title}
                className="overlayed"
          />
        </div>
      );
    }
    document.body.classList.toggle('noscroll', Boolean(workToOpen));

    const filtersMenu = <div className={(leftMenuFixed ? 'fixed ' : '') + 'category-menu'} id="js-category-menu">
      {filters.map(category =>
        <Link key={category.id} className={(category.id === categoryId ? 'active ' : '') + 'category-name'}
              to={'/portfolio' + (category.id ? '?category=' + category.id : '')}>
          {category.title}
        </Link>
      )}
      <div className="active-highlighter"> </div>
    </div>;

    const workList = <div className="works">
      {worksFiltered.map(work =>
        <WorkSummary work={work}
                     categoryId={categoryId}
                     categoryTitle={categories.find(category => category.id === work.categoryId).title}
                     key={work.id}/>,
      )}
    </div>;

    return (
      <div className="content portfolio">
        <h1 className={works.length ? 'page-heading' : 'loading'}><span className="transparent">My</span> works</h1>
        <div className="page-content">
          {filtersMenu}
          {workList}
          {workRender}
        </div>
        {!works.length && <div className="full-screen-preloader-container"><Preloader/></div>}
      </div>
    );
  }
}

export default Portfolio;