import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Preloader from './Preloader';

class WorkSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {imageClassName: null, previewImageUrl: ''};
  }

  componentDidMount() {
    this.setState({imageClassName: 'loading'}, () => {
      if (!this.state.previewImageUrl) {
        // If url is not resolved yet, get it
        this.props.work.previewPromise.then(previewImageUrl => this.setState({previewImageUrl}));
      }
    });
  }

  handleImageLoad = () => {
    this.setState({imageClassName: null});
  };

  handleImageEvent = className => () => {
    if (this.state.imageClassName !== 'loading') {
      this.setState({imageClassName: className});
    }
  };

  render() {
    const {work, categoryId, categoryTitle} = this.props;
    const linkToWork = `/portfolio/${work.id}` + (categoryId ? '?category=' + categoryId : '');
    const {imageClassName, previewImageUrl} = this.state;
    const makeLink = (content, className = '') => (
      <Link to={linkToWork} className={className}
            onTouchStart={this.handleImageEvent('mouseover')} onTouchEnd={this.handleImageEvent(null)}
            onMouseEnter={this.handleImageEvent('mouseover')} onMouseLeave={this.handleImageEvent(null)}>
        {content}
      </Link>
    );
    return (
      <div className="work">
        <div className="title">
          <div className="category-name">{categoryTitle}</div>
          <h2 className="work-title">{makeLink(work.title)}</h2>
          {makeLink('Open', 'action-link')}
        </div>
        <div className="preview">
          {makeLink(<img className={imageClassName}
                         src={previewImageUrl} alt={work.title + ' Preview'}
                         onLoad={this.handleImageLoad}/>, 'image-link')}
          {(!previewImageUrl || imageClassName === 'loading')
            && <div className="img-preloader"><Preloader/></div>}
        </div>
      </div>
    );
  }
}

export default WorkSummary;